vue
<template>
    <div>
        <div class="p-grid">
            <div class="p-col-12 p-d-flex p-jc-center">
                <p class="title-bold">Aumento de glúteos sin cirugía</p>
            </div>
        </div>

        <div class="p-grid">
            <div class="p-col-12 p-d-flex p-jc-center">
                <img class="img-fluid" alt="levantamiento de gluteos sin operacion" src="../../assets/levantamiento-gluteos.svg" />
            </div>

            <div class="p-col-10 p-offset-1 p-d-flex p-jc-center">
                <Card styleClass="p-card-shadow">
                    <template #content>
                        <p>
                            <span class="p-text-bold">Elevación de glúteos</span><br>
                            Existen diferentes técnicas para poder elevar los glúteos sin tener que realizar una cirugía,
                            con resultados muy naturales y no permanentes en el tiempo. Una técnica consiste en tensar y
                            reafirmar los glúteos de forma natural, gracias al uso de <span class="p-text-bold">hilos
                                tensores</span>.
                        </p>

                        <ul>
                            <li>
                                Podemos utilizar hilos especialmente diseñados para ser insertados en el cuerpo humano, ya
                                que son totalmente compatibles y reabsorbibles con el tiempo por el propio organismo, por lo
                                que prácticamente no existen alergias ni rechazos.
                            </li>
                            <li>
                                No tienen las molestias de una operación, y después de colocarlos puedes realizar tus
                                actividades cotidianas.
                            </li>
                        </ul>

                        <p>
                            Si, además de levantar los glúteos con hilos, quieres redondearlos y marcarlos un poco más, se
                            puede complementar este tratamiento con <span class="p-text-bold">ácido hialurónico</span>.
                        </p>

                        <p>
                            <span class="p-text-bold">Aumento de glúteos con ácido hialurónico</span><br>
                            El ácido hialurónico de alta densidad es una sustancia reabsorbible que se aplica entre el
                            músculo y la piel, y produce un aumento inmediato del volumen de los glúteos. Con ella, se
                            rellena la zona a través de una mínima incisión.
                        </p>

                        <p>
                            Esta sustancia es biocompatible con el organismo, al igual que los hilos tensores, por lo que
                            está libre de rechazos o alergias. El procedimiento consiste en inyectar ácido hialurónico sobre
                            la dermis de los glúteos y masajear la zona para que el contenido se expanda de manera uniforme.
                        </p>

                        <p>
                            Los resultados son inmediatos pero no permanentes, ya que su duración puede oscilar entre un año
                            y medio a dos años. Tras el tratamiento se puede realizar las actividades cotidianas, siendo el
                            tiempo de tratamiento de entre 30 a 90 min.
                        </p>

                        <p>
                            No obstante, el aumento de glúteos con ácido hialurónico es un tratamiento estético temporal.
                            Los resultados son efectivos e inmediatos, pero no se mantienen a lo largo del tiempo. La
                            duración aproximada será de un año y medio, dependiendo de la cantidad inyectada de relleno y
                            las necesidades de cada persona, por lo que pasado ese tiempo es recomendable hacer retoques con
                            menor cantidad.
                        </p>

                        <p>
                            El paciente se puede incorporar a su vida diaria inmediatamente, ya que apenas notará molestias
                            tras la aplicación.
                        </p>
                    </template>

                    <template #footer>
                        <span>Para más información no dude en ponerse en contacto con nuestro
                            Centro médico al 637 857 384.</span>
                    </template>
                </Card>
            </div>
        </div>
    </div>
</template>

<script>
import Card from 'primevue/card';
export default {
    name: 'AumentoGluteosVue',

    data() {
        return {
            // Propiedades de datos del componente
        };
    },
    components: {
        Card
    },
    methods: {
        // Métodos del componente
    },
    computed: {
        // Propiedades computadas del componente
    },
    created() {
        // Lógica que se ejecuta cuando el componente se crea
    },
    mounted() {
        // Lógica que se ejecuta cuando el componente se monta en el DOM
    },
    destroyed() {
        // Lógica que se ejecuta cuando el componente se destruye
    },
};
</script>

<style scoped>.title-bold {
    font-size: 30px;
    font-weight: bold
}

.img-fluid {
    width: 100%;
    height: auto;
}

@media (min-width: 576px) {
    .img-fluid {
        max-width: 50%;
        height: auto;
    }
}</style>